<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <SelectorDeliverablesVue
      dark
      v-model="deliverables"
      autoload
      label="Select Service Deliverables"
      helpText="This is a set of the deliverables that will be presented as a main deliverables and examples for the customer."
      multiple
      return-object
      @onItemClick="(del) => $emit('onDeliverableClick', del)"
    ></SelectorDeliverablesVue>
  </v-form>
</template>


<script>
import SelectorDeliverablesVue from "../../../atoms/common/autocompletes/SelectorDeliverables.vue";
import _ from "lodash";

export default {
  props: {
    value: {
      default: () => ({
        serviceDeliverables: [],
      }),
    },
  },
  data() {
    const { deliverables } = this.prepareLists(this.value);

    return {
      pValid: true,
      deliverables: deliverables,
      form: this.value,

      // ======rules
      nameRules: [(v) => !!v || "Section Name is required"],
    };
  },
  components: {
    SelectorDeliverablesVue,
  },
  methods: {
    prepareLists(value) {
      let deliverables = [];

      if (value.serviceDeliverables && value.serviceDeliverables.length)
        deliverables = value.serviceDeliverables.map((t) => t.deliverable);

      return { deliverables };
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    value: {
      handler(newVal) {
        const { deliverables } = this.prepareLists(newVal);

        this.deliverables = Object.assign(this.deliverables, deliverables);

        this.newLink = "";

        this.form = newVal;
      },
      deep: true,
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
    deliverables: {
      handler(newVal) {
        console.log("TAGS UPDATED");
        this.form.serviceDeliverables = newVal.map((deliverable, i, arr) => {
          const existed = _.find(
            arr,
            (t) => t.deliverable_id === deliverable.id
          );

          return existed
            ? existed
            : {
                deliverable,
              };
        });
      },
      deep: true,
    },
  },
};
</script>


<style lang="scss" >
.deliverables-card {
  height: fit-content !important;
}
</style>