<template>
  <AView
    :value="value"
    :name="service.name"
    :active="service.id === activeServiceId"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onSave="onSave"
    @onClear="onClear"
    @onNavigate="onNavigate"
    @onHover="onHover"
  >
    <template v-slot:content="{}">
      <v-card class="ma-2 transparent elevation-0">
        <v-row>
          <v-col>
            <FormSection
              right
              underline
              class=""
              :label="'Services Deliverables'"
              :icon="value.view.display.icon"
              :actions="actions"
              :editable="!!actions.length"
              :payload="{
                service,
                from: value,
              }"
            ></FormSection>
          </v-col>
        </v-row>

        <ServiceSectionFormDeliverablesVue
          ref="form"
          v-model="service"
          @onDeliverableClick="onDeliverableClick"
        ></ServiceSectionFormDeliverablesVue>
      </v-card>
    </template>
  </AView>
</template>
    
    <script>
import { mapGetters, mapState } from "vuex";
import { PermissionsHelper } from "../../../../../../../components/helpers/permissions.helper";
import FormSection from "../../../../../../../components/wad/atoms/common/FormSection.vue";
import AView from "../../../../../../../components/wad/organisms/layout/A-View.vue";
import ServiceSectionFormDeliverablesVue from "../../../../../../../components/wad/organisms/services/editorForms/ServiceSectionFormDeliverables.vue";
import { DELIVERABLE_BUTTONS } from "../../../constants/actions.gh";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    ServiceSectionFormDeliverablesVue,
    FormSection,
  },
  data() {
    return {
      service: {},
    };
  },
  created() {
    this.$store.dispatch("ServiceStore/GetService", {
      id: this.value.relation.params.serviceId,
    });

    this.service = this.serviceById(this.value.relation.params.serviceId);
  },

  computed: {
    ...mapState("ServiceStore", ["activeServiceId", "displayedServices"]),
    ...mapGetters("ServiceStore", ["serviceById"]),
    actions: function () {
      return PermissionsHelper.getActions(
        [DELIVERABLE_BUTTONS.LIST, DELIVERABLE_BUTTONS.CREATE_NEW],
        this.value.permissions
      );
    },
  },

  methods: {
    onFocus() {},
    onSave() {},
    onClose() {},
    onClear() {},
    onDeliverableClick(deliverable) {
      this.$store.dispatch("DeliverableStore/openDeliverableDetails", {
        from: this.value,
        deliverable,
      });
    },
    onNavigate() {
      this.$store.dispatch("ServiceStore/setActiveSection", {
        serviceId: this.value.relation.params.serviceId,
        section: "deliverables",
      });
    },
    onHover() {
      this.$store.commit(
        "ServiceStore/setActiveId",
        this.value.relation.params.serviceId
      );
    },
  },
  watch: {
    displayedServices: {
      async handler(newVal) {
        if (newVal && !newVal.IN_PROGRESS) {
          this.service = this.serviceById(this.value.relation.params.serviceId);
        }
      },
      deep: true,
    },
    service: {
      async handler(newVal, oldVal) {
        if (!(oldVal && oldVal.id)) return;

        if (this.$refs.form && this.$refs.form.validate())
          await this.$store.dispatch("ServiceStore/UpdateService", {
            service: newVal,
          });
      },
      deep: true,
    },
  },
};
</script>